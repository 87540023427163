import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const GrundbuchKosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Das Grundbuch – Welche Kosten dich erwarten" showCalc={false} />
            <Article>
                <p>
                    Im Grundbuch werden Grundstücke, Eigentümer*innen und alle dabei bestehenden Rechte und Belastungen
                    aufgezeichnet. Das Grundbuch ist für alle öffentlich einsehbar. Der Grundbuchauszug kostet meist
                    14,40€ und kann bei jedem Bezirksgericht, Notar und auch online angefragt werden. Für die Eintragung
                    ins Grundbuch musst du allerdings recht hohe Gebühren zahlen, genauso wie auch für jede Änderung und
                    Löschung. Wie es mit den{" "}
                    <Link to="/artikel/notarkosten/" target="_blank" rel="noreferrer noopener">
                        Kosten
                    </Link>{" "}
                    rund ums Grundbuch genauer aussieht, erfährst du in diesem Artikel.
                </p>
                <hr />

                <h2>Was steht im Grundbuch eigentlich?</h2>
                <p>
                    Das Grundbuch beinhaltet die wichtigsten Informationen zu jeder Liegenschaft. Es besteht aus einem
                    A-Blatt, in dem Angaben zur Liegenschaft gemacht werden, dem B-Blatt, in welchem Angaben zu
                    Eigentümer*innen zu finden sind und einem C-Blatt, wo die Angaben zu Belastungen der Liegenschaft
                    stehen, falls welche vorhanden sind.
                </p>
                <hr />

                <h2>Welche Kosten erwarten mich bei der Eintragung als Eigentümer*in?</h2>
                <p>
                    Sobald du eine Grundstück oder eine Immobilie gekauft hast und dessen Eigentümer*in bist, musst du
                    die Eintragung als Eigentümer*in ins Grundbuch bezahlen. Diese Kosten hängen von dem Kaufpreis und
                    von der{" "}
                    <Link to="/artikel/hypothekendarlehen/" target="_blank" rel="noreferrer noopener">
                        Hypothek
                    </Link>{" "}
                    ab, weil sie einen Prozentteil davon ergeben. Dieser setzt sich folgendermaßen zusammen:
                </p>
                <ul>
                    <li>1,1 Prozent des Kaufpreises</li>
                    <li>1,2 Prozent vom Wert des Pfandrechts (falls eine Hypothek eingetragen wird)</li>
                </ul>
                <p>
                    Außerdem ist eine kleine Eingabegebühr zu zahlen, wenn der Antrag für die Eintragung nicht
                    elektronisch gestellt wird.
                </p>
                <p>
                    <strong>Nicht vergessen:</strong> Jeder Grundbucheintrag muss von einem Notar oder einer Notarin
                    beglaubigt werden, wofür ebenfalls Kosten anfallen. Die{" "}
                    <Link to="/artikel/notarkosten/" target="_blank" rel="noreferrer noopener">
                        Notarkosten
                    </Link>{" "}
                    unterscheiden sich dabei je nach Tarif.
                </p>
                <hr />

                <h2>Verschiedene Arten von Eintragungen ins Grundbuch</h2>
                <p>Insgesamt gibt es übrigens drei Arten von Eintragungen ins Grundbuch:</p>
                <ul>
                    <li>die Vormerkung</li>
                    <li>die Anmerkung</li>
                    <li>die Einverleibung – die eigentliche Eintragung im Eigentumsblatt</li>
                </ul>
                <p>
                    Die Einverleibung stellt die wichtigste Eintragung dar, wenn du Eigentum gekauft hast. Dieser
                    Eintrag im Grundbuch macht dich nämlich offiziell zu*r Eigentümer*in. Ungefähr zwei Wochen vor
                    deinem Kauf solltest du den bestehenden Grundbucheintrag auf jeden Fall ansehen. Dadurch kannst du
                    nämlich rechtzeitig feststellen, ob z.B. irgendwelche lebenslangen Wohnrechte, ausstehende
                    Hypotheken oder andere Belastungen an der Liegenschaft bestehen, die du ansonsten unwissentlich
                    übernehmen würdest.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratung anfordern!
                    </a>
                </p>
                <hr />

                <h2>Weitere Kosten, die auf dich zukommen</h2>
                <p>
                    Ein Grundbucheintrag bedeutet leider nicht, dass du für immer in diesem Register stehen bleibst.
                    Jeder Eintrag kann gegen eine Gebühr auch geändert oder gelöscht werden. Bei Anträgen wie der
                    Löschung, der Namensänderung, oder einer Adressänderung kommt eine Gebühr von 44 Euro auf dich zu.
                    Die Voraussetzung ist dabei, dass diese Summe bereits im voraus oder während der Übergabe des
                    Antrags gezahlt wird, ansonsten ist mit einem Aufschlag zu rechnen.
                </p>
                <p>
                    Viele Anträge, Grundbucheintragungen oder Änderungen durchführen zu lassen, kann man auch online
                    erledigen. Die Preise sind dabei je nach Art der Anfrage unterschiedlich. Wenn du lediglich einen
                    (nicht beglaubigten) Grundbuchauszug benötigst, kostet dich das bei deinem Bezirksgericht ca. 14
                    Euro.
                </p>
                <p>
                    Normalerweise bezahlt man für eine Löschung im Grundbuch auch einen Notar oder einen Notarin. Diese
                    Kosten kannst du dir sparen, wenn du einen schriftlichen, formell korrekten Antrag beim
                    Bezirksgericht einreichst. Das Problem dabei ist, dass man juristische Fachkenntnisse benötigt, um
                    einen korrekten Antrag zu schreiben. Aus diesem Grund hilft aber beispielsweise die Arbeiterkammer,
                    indem sie für häufige Änderungen im Grundbuch online Muster zur Verfügung stellt. Dort musst du dann
                    lediglich deine Daten eintragen.
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Ein Grundbucheintrag ist mit Kosten verbunden, diese halten sich bei vielen Aktionen jedoch in
                    Grenzen. Zusätzlich kann man sich die Notarkosten{" "}
                    <Link to="/artikel/sparen-immobilien-kauf/" target="_blank" rel="noreferrer noopener">
                        sparen
                    </Link>
                    . Allerdings ist auch wichtig zu betonen, dass Grundbucheinträge das Fundament deiner Rechte als
                    Eigentümer*in deiner Liegenschaft bildet. Das sollte man ernst nehmen und nicht zögern, in die
                    entsprechenden Einträge zu investieren, sodass alles korrekt abläuft.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"grundbuchKosten"}></BreadcrumbList>
            <ArticleStructuredData page={"grundbuchKosten"} heading={"Das Grundbuch – Welche Kosten dich erwarten"} />
        </Layout>
    );
};

export default GrundbuchKosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.grundbuch-kosten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
